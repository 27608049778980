.timeline{
    padding: 0px !important;

}

.timeline .timeline-header{
    padding-top: 7px;
}

.timeline .timeline-top{
    min-height: 80px !important;
}

.timeline .MuiTimelineItem-root{
    min-height: 0px;
}

.timeline .MuiTimelineItem-missingOppositeContent:before{
    display: none;
}

.timeline .timeline-dot-header{
    color: var(--white-color);
    background-color: var(--blue-color);
    font-size: small;
    padding: 10px;
}

.timeline .timeline-dot{
    color: black;
    background-color: var(--blue-color);
    /* font-size: small; */
    padding: 2px;
}

.timeline .MuiTimelineConnector-root{
    background-color: var(--white-color);
}

.timeline .timeline-dot-header > .MuiSvgIcon-root{
font-size: 20px;
color: var(--white-color);
}

.timeline .separator-padding{
    padding-left: 18px;
}

.timeline .timeline-content{
    padding-top: 0px;
    margin-bottom: 20px;
}


