:root{
  --yellow-color: #ffc500;
  --blue-color: #323B4C;
  --black-color: #111;
  --white-color: #eee;
  --grey-color: #777;
}

.top-60 {
  margin-top: 60px !important;
}

.top-30 {
  margin-top: 30px !important;
}

.top-20 {
  margin-top: 20px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.padding-30{
  padding: 30px !important;
}

.padding-bottom-45{
  padding-bottom: 45 !important;
}

.margin-bottom-30{
  margin-bottom: 30px !important;
}

@media (max-width:980px) {
  .top-60 {
    margin-top: 30px !important;
  }
}

.container-shadow{
    box-shadow: 0px 2px 92px 0px rgba(0,0,0,0.13);
    -webkit-box-shadow: 0px 2px 92px 0px rgba(0,0,0,0.13);
    -moz-box-shadow: 0px 2px 92px 0px rgba(0,0,0,0.13);
}

.main-content{
  background-color: white;
 min-height: 600px; 
 border-radius: 5px;
 margin-bottom: 30px;
 padding: 20px;
}

.title-styling{
  position: relative;
  display: inline-block;
  padding: 0px 3px;
}

.title-styling-text{
  font-size:  18px;
  font-weight: 600;
  position: relative;
  margin: 0px;
}

.title-styling span{
  position: absolute;
  height: 7px;
  width: 100%;
  bottom: 0;
  opacity: 0.4;
  left: 0;
  background-color: var(--blue-color);
}