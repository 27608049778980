/* skills */

.skills{
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 0px 48px 0px rgba(4,6,4,0.08) !important;
    min-height: 186px;
    height: 100%;
}
    
.skills-title{
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 35px !important;
}
    
.skills-description{
    display: flex;
    align-items: center;
    color: var(--grey-color) !important;
    font-weight: 400 !important;
    line-height: 18px !important;
}
    
.skills-timeline-dot{
    color: black !important;
    border-color: var(--blue-color) !important;
    padding: 2px !important;
    margin-right: 5px !important;
}

/* projects */

.custom-card{
    /* display: flex; */
    height: 100%;
    /* max-width: 350px; */
    /* min-width: 250px; */
    box-shadow: 0px 0px 48px 0px rgba(4,6,4,0.08) !important;
    border-radius: 5px;
    overflow: hidden;
    outline-width: 0px !important;
}

.custom-card .custom-card-image{
    height: 140px;
}

.custom-card .custom-card-title{
    font-weight: 600;
}

.custom-card .custom-card-caption{
    color: var(--grey-color);
    white-space: pre-line;
}

/* Dialog */

/* .project-dialog{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
} */

.project-dialog .project-dialog-image{
    max-height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: center center;   
}

.project-dialog .project-dialog-description{
    margin-top: 10px !important;
    white-space: pre-line;
}

.project-dialog .project-dialog-action{
    justify-content: center !important;
    margin-bottom: 10px;
}

.project-dialog .project-dialog-icon{
    color: var(--grey-color);
    margin: 0px 12px;
    cursor: pointer;
}

.project-dialog .project-dialog-icon :hover{
    color: var(--blue-color);
}

.project-dialog .project-dialog-icon > .MuiSvgIcon-root {
    font-size: 30px !important;
}
