.profile{
    background-color: var(--blue-color);
    border-radius: 5px;
    width: 100%;
    display: inline-block;
    min-height: 700px !important;
}

.profile-name{
    line-height: 18px;
    padding: 20px;
    color:var(--white-color);
}

.profile-name .name{
    text-transform: uppercase;
    font-size: 17px;
    font-weight: bold;
}

.profile-name .title{
    font-size: 13px;
    color: var(--white-color);
}

.profile-image{
    margin-top: -10px;
    /* -webkit-clip-path: polygon (0 9%, 100% 0, 100% 94%, 0% 100%);
    clip-path: polygon(0 9%,100% 0, 100% 94%, 0% 100%); */
    text-align: center;
}

.profile-image img{
    width: 90%;
    border-radius: 50%;
}

.profile-information{
    top: 0px;
    left: 0px;
    margin: -40px 10px 30px;
    white-space: pre-line;
}

.profile-information .timeline-content{
    margin-top: 2px;
    margin-bottom: 10px;
}

.timelineItem-text{
    font-size: 14px !important;
    color: var(--white-color) !important;
    font-weight: 400 !important;
    text-decoration: none !important;
}

.timelineItem-text > a{
    font-size: 14px !important;
    color: var(--white-color) !important;
    
}

.timelineItem-text > span{
    font-size: 16px ;
    color: var(--white-color);
    font-weight: 600;
}

.profile .button-container{
width: 100%;
display: flex;
justify-content: center;
margin-top: 40px;
}

/* .profile-dot-header{
    color: var(--blue-color) !important;
    background-color: var(--blue-color) !important;
    font-size: small;
    padding: 8px !important;
} */


.profile-dot{
    color: var(--blue-color);
    background-color: var(--blue-color) !important;
    /* font-size: small; */
    padding: 2px !important;
}

.profile-timeline{
    padding: 0px !important;

}
.profile-timeline .MuiTimelineConnector-root{
    background-color: var(--blue-color);
}

/* .profile-timeline .timeline-dot-header > .MuiSvgIcon-root{
font-size: 20px;
color: var(--white-color);
} */

.profile-separator-padding{
    padding-left: 18px;
}

.profile-timeline .MuiTimelineItem-missingOppositeContent:before{
    display: none;
}


