.site-button{
    background-color: var(--white-color) !important;
    color: var(--blue-color) !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    border: 0 !important;
    border-radius: 50px !important;
    padding: 1px 12px !important;
    line-height: 35px !important;
    min-width: 100px !important;
    outline: none !important;
    text-decoration: none;
}

.button-icon-container{
color: var(--white-color) !important;
background: var(--blue-color) !important;
border-radius: 50px !important;
height: 27px  !important;
width: 27px  !important;
line-height: 29px !important;
text-align: center !important;
display: flex !important;
align-items: center !important;
justify-content: center !important;

}

.button-icon-container > .MuiSvgIcon-root{
font-size: 16px !important;
}

.site-button .button-text{
color: var(--blue-color) !important;
font-size: 14px !important;
font-weight: 500;

text-transform: none !important;
text-align: center !important;
width: 100% !important;
margin-right: 5px !important;
margin-left: 5px !important;
}
