.footer{
    background-color: white;
    color: var(--black-color);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    border-radius: 5px;
    padding: 20px 25px;
    box-shadow: 0px 2px 92px 0px rgba(0,0,0,0.07);
}

.footer-copyright{
font-size: small;
text-align: end;
color: var(--grey-color);
}

.footer-left{
    font-size: small;
    text-align:start;
    color: var(--grey-color);
    }

.footer-left img{
   width: 75px;
   margin-right: 15px;
}

.ccna{
    height: 65px;
    width: auto !important;
}

.footer-copyright > a{
    color: var(--main-color);
}