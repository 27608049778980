.header{
    margin-bottom: 30px !important;
    background-color: white !important;
    padding: 0 22px 0 0 !important;
    border-radius: 5px !important;
    box-shadow: 0px 2px 92px 0px rgba(0,0,0,0.07);
    display: flex;
}

.header-link{
    font-weight: 500;
    color: var(--black-color) !important;
}

.header-link:hover{
    font-weight: 500;
    color: var(--blue-color) !important;
}

.header-home{
    background-color: var(--blue-color);
    color: var(--white-color);
    padding: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

 .header-left{
    margin-right: auto;
}

.header-right{
    display: flex !important;
    align-items: center !important;
   
}

.header-right > a > .MuiSvgIcon-root{
    font-size: 18px !important;
    margin-right: 12px;
    color: var(--black-color);
}

