.timeline-name{
    font-size: 16px !important;
    font-weight: 500 !important;
}

.timeline-date{
    color: var(--black-color)
}


.timeline-description{
    color: var(--grey-color);
    font-size: small !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    line-height: 1.6 !important;
    /* white-space: pre-line; */
}



    
    /* .timeline-dot{
    color: black !important;
    border-color: var(--main-color) !important;
    padding: 2px !important;
    margin-right: 5px !important;
    } */
