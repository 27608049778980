.about-me-text{
    color: var(--black-color);
    font-size: smaller;
    line-height: 1.6 !important;
    white-space: pre-line;
}

.interest-name{
    font-size: 16px !important;
    font-weight: 500 !important;
    /* margin-top: 20px !important; */
}

.interest-description{
    color: var(--black-color);
    font-size: small !important;
    margin-top: 5px !important;
    /* margin-bottom: 16px !important; */
    
    line-height: 1.6 !important;
    white-space: pre-line;
}